// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./Gz4G5RkTl-0.js";

const cycleOrder = ["Aoe2oo59W", "oHgn1OoXU", "b5ssJcWv9", "vONRRic1Z", "RwkC1Tf2u", "pOhyeY6JH", "JjJgYc1r4", "LM_Wn6o4t"];

const serializationHash = "framer-7F9wb"

const variantClassNames = {Aoe2oo59W: "framer-v-1eq5jk1", b5ssJcWv9: "framer-v-u044fy", JjJgYc1r4: "framer-v-16mi7sf", LM_Wn6o4t: "framer-v-cg797h", oHgn1OoXU: "framer-v-1j7hywl", pOhyeY6JH: "framer-v-tbv8xq", RwkC1Tf2u: "framer-v-11f9l2e", vONRRic1Z: "framer-v-1w2nrgf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {C3NO8bylo: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"E-commerce": "pOhyeY6JH", "Técnica": "b5ssJcWv9", Cientifica: "oHgn1OoXU", eLearning: "JjJgYc1r4", Juridica: "Aoe2oo59W", legendagem: "RwkC1Tf2u", marketing: "vONRRic1Z", SEO: "LM_Wn6o4t"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Aoe2oo59W"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Aoe2oo59W", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1eq5jk1", className, classNames)} data-framer-name={"Juridica"} layoutDependency={layoutDependency} layoutId={"Aoe2oo59W"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({b5ssJcWv9: {"data-framer-name": "Técnica"}, JjJgYc1r4: {"data-framer-name": "eLearning"}, LM_Wn6o4t: {"data-framer-name": "SEO"}, oHgn1OoXU: {"data-framer-name": "Cientifica"}, pOhyeY6JH: {"data-framer-name": "E-commerce"}, RwkC1Tf2u: {"data-framer-name": "legendagem"}, vONRRic1Z: {"data-framer-name": "marketing"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={getLocalizedValue("v0", activeLocale) ?? <React.Fragment><motion.h2 style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "36px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.3px", "--framer-line-height": "1.25em", "--framer-text-color": "var(--extracted-1of0zx5, var(--token-20608b9f-0145-4a1e-b971-ee948ebbb015, rgb(255, 255, 255)))"}}>O que acontece depois disso?</motion.h2></React.Fragment>} className={"framer-1frbsr8"} data-framer-name={"Title 1"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"MsH1u_S_Q"} style={{"--extracted-1of0zx5": "var(--token-20608b9f-0145-4a1e-b971-ee948ebbb015, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7F9wb.framer-194wcae, .framer-7F9wb .framer-194wcae { display: block; }", ".framer-7F9wb.framer-1eq5jk1 { align-content: flex-end; align-items: flex-end; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 402px; }", ".framer-7F9wb .framer-1frbsr8 { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7F9wb.framer-1eq5jk1 { gap: 0px; } .framer-7F9wb.framer-1eq5jk1 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-7F9wb.framer-1eq5jk1 > :first-child { margin-top: 0px; } .framer-7F9wb.framer-1eq5jk1 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 402
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"oHgn1OoXU":{"layout":["fixed","auto"]},"b5ssJcWv9":{"layout":["fixed","auto"]},"vONRRic1Z":{"layout":["fixed","auto"]},"RwkC1Tf2u":{"layout":["fixed","auto"]},"pOhyeY6JH":{"layout":["fixed","auto"]},"JjJgYc1r4":{"layout":["fixed","auto"]},"LM_Wn6o4t":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGz4G5RkTl: React.ComponentType<Props> = withCSS(Component, css, "framer-7F9wb") as typeof Component;
export default FramerGz4G5RkTl;

FramerGz4G5RkTl.displayName = "3Titulo";

FramerGz4G5RkTl.defaultProps = {height: 90, width: 402};

addPropertyControls(FramerGz4G5RkTl, {variant: {options: ["Aoe2oo59W", "oHgn1OoXU", "b5ssJcWv9", "vONRRic1Z", "RwkC1Tf2u", "pOhyeY6JH", "JjJgYc1r4", "LM_Wn6o4t"], optionTitles: ["Juridica", "Cientifica", "Técnica", "marketing", "legendagem", "E-commerce", "eLearning", "SEO"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerGz4G5RkTl, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZ1rib2Bg-4.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})